import React, { Fragment, useEffect, useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Meta from './Meta'
import Nav from './Nav'
import Footer from './Footer'
import 'modern-normalize/modern-normalize.css'
import '../../styles/global.scss'
import '../../fonts/fonts.css'
import { useLocation } from '@reach/router'

const getReferrer = () => {
  console.log('Running getReferrer function')

  function getQueryParams() {
    console.log('Extracting query params')

    var params = {}
    window.location.search
      .substr(1)
      .split('&')
      .forEach(function(pair) {
        var keyValue = pair.split('=')
        params[keyValue[0]] = decodeURIComponent(keyValue[1])
      })
    console.log('Query params:', params)

    return params
  }

  var utmParams = getQueryParams()
  var referrer = document.referrer
  var currentPage = window.location.href

  console.log('UTM Params:', utmParams)
  console.log('Referrer:', referrer)
  console.log('Current Page:', currentPage)

  if (utmParams.utm_source) {
    sessionStorage.setItem('utm_source', utmParams.utm_source)
    sessionStorage.setItem('utm_medium', utmParams.utm_medium)
    sessionStorage.setItem('utm_campaign', utmParams.utm_campaign)
    console.log('Stored UTM params in localStorage')
  }

  if (referrer) {
    sessionStorage.setItem('referrer', referrer)
    console.log('Stored referrer in localStorage')
  }

  if (!sessionStorage.getItem('website_origin')) {
    sessionStorage.setItem('website_origin', currentPage)
    console.log('Stored current page in localStorage')
  }
}

export default ({ children, meta, title }) => {
  const location = useLocation()

  const url = `https://atlas.co${location.pathname}`

  useEffect(() => {
    console.log('Location pathname changed:', location.pathname)

    if (!document || location.hash) return
    getReferrer()
  }, [location.pathname])

  useEffect(() => {
    if (!document || location.hash) return
    document.body.scrollTop = 0
  }, [location.pathname])

  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          settingsYaml {
            siteTitle
            siteDescription
            socialMediaCard {
              image
            }
          }
          allPosts: allMarkdownRemark(
            filter: { fields: { contentType: { eq: "postCategories" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { siteTitle, socialMediaCard, googleTrackingId } =
            data.settingsYaml || {},
          subNav = {
            posts: data.allPosts.hasOwnProperty('edges')
              ? data.allPosts.edges.map(post => {
                  return { ...post.node.fields, ...post.node.frontmatter }
                })
              : false
          }

        return (
          <Fragment>
            <Meta
              googleTrackingId={googleTrackingId}
              absoluteImageUrl={
                socialMediaCard &&
                socialMediaCard.image &&
                socialMediaCard.image
              }
              {...meta}
              {...data.settingsYaml}
              url={url}
            />
            <link
              rel="preload"
              href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
              as="style"
              onload="this.onload=null;this.rel='stylesheet'"
            ></link>
            <noscript>
              <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
              />
            </noscript>

            <div
              style={{
                height: 'calc(100vh - 64px)'
              }}
            >
              <Nav />
              <Fragment>{children}</Fragment>
              <Footer />
            </div>
          </Fragment>
        )
      }}
    />
  )
}
