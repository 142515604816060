import React from 'react'

import styles from './Footer.module.scss'
import Button from './Button'
import { signUpClick } from '../../utils/tracker'
import { InstagramIcon, LinkedInIcon, XIcon } from '@atlas-co/icons'
import { Link } from 'gatsby'
import { Tooltip } from '@mantine/core'

export default () => (
  <>
    <div className={styles.footer}>
      <div className={styles.footerImageBg} />
      <div className={styles.footerHeader}>
        <h3>Ready to level up your map-making process?</h3>
        <p>Create, collaborate, share — all under one roof</p>
        <div style={{ marginTop: '2rem' }}>
          <Button
            href="https://app.atlas.co/login"
            size="xl"
            onClick={signUpClick}
            className={'plausible-event-name=Signup'}
          >
            Sign up -{' '}
            <span
              style={{
                fontWeight: 300,
                fontSize: '0.75em',
                marginTop: '-1rem'
              }}
            >
              it's free
            </span>
          </Button>
        </div>
      </div>
      <div className={styles.footerContent}>
        <div className={styles.footerGrid}>
          <div>
            <ul class={styles.listUnstyled}>
              <li>
                <p>Product</p>
              </li>
              <li>
                <a href="/product/">Overview</a>
              </li>
              <li>
                <a href="/product/visualization/">Visualization</a>
              </li>
              <li>
                <a href="/product/spatial-analysis/">Spatial Analysis</a>
              </li>
              <li>
                <a href="/product/widgets/">Spatial Analysis</a>
              </li>
              <li>
                <a href="/product/builder/">Builder Mode</a>
              </li>
            </ul>
          </div>
          <div>
            <ul class={styles.listUnstyled}>
              <li>
                <p>Solutions</p>
              </li>
              <li>
                <a href="/solutions/buildings-real-estate/">
                  Buildings & Real Estate
                </a>
              </li>
              <li>
                <a href="/solutions/energy-infrastructure/">
                  Energy & Infrastructure
                </a>
              </li>
              <li>
                <a href="/solutions/climate-risk-sustainability/">
                  Climate Risk & Sustainability
                </a>
              </li>
              <li>
                <a href="/solutions/public-service-administration/">
                  Public Service & Administration
                </a>
              </li>
              <li>
                <a href="/solutions/retail-marketing/">Retail & Marketing</a>
              </li>
            </ul>
          </div>
          <div>
            <ul class={styles.listUnstyled}>
              <li>
                <p>Resources</p>
              </li>
              <li>
                <a href="/resources/">Blog</a>
              </li>
              <li>
                <a href="/community/maps/">Examples</a>
              </li>
              <li>
                <a href="/help/">Help Center</a>
              </li>
              <li>
                <a href="/help/docs/">Documentation</a>
              </li>
              <li>
                <a href="/help/guides/">Guides</a>
              </li>
              <li>
                <a href="/formats/">GIS Formats</a>
              </li>
              <li>
                <a href="/glossary/">Technical Glossary</a>
              </li>
            </ul>
          </div>
          <div>
            <ul class={styles.listUnstyled}>
              <li>
                <p>Company</p>
              </li>
              <li>
                <a href="/careers/">Careers</a>
              </li>
              <li>
                <a href="/pricing/">Pricing</a>
              </li>
              <li>
                <a href="/resources/">Resources</a>
              </li>
              <li>
                <p>Legal</p>
              </li>
              <li>
                <a href="/privacy-policy/">Privacy Policy</a>
              </li>
              <li>
                <a href="/privacy-policy/">Cookie Policy</a>
              </li>
              <li>
                <p>Support</p>
              </li>
              <li>
                <a href="mailto:help@atlas.co">Talk to support</a>
              </li>
            </ul>
          </div>
          <div>
            <ul class={styles.listUnstyled}>
              <li>
                <p>Trending posts</p>
              </li>
              <li>
                <a href="/blog/get-better-at-using-color-palettes-with-choropleth-maps/">
                  Get better at using color palettes with choropleth maps
                </a>
              </li>
              <li>
                <a href="/blog/free-data-sources-for-environmental-data/">
                  Free Data Sources for Environmental Data
                </a>
              </li>
              <li>
                <a href="/blog/new-in-atlas-flexible-popups/">
                  New in Atlas: Flexible Popups
                </a>
              </li>
              <li>
                <a href="/blog/reveal-patterns-with-heatmaps/">
                  Reveal Patterns with Heatmaps
                </a>
              </li>
              <li>
                <a href="/blog/travel-time-analysis-to-analyze-locations/">
                  Travel Time Analysis to Analyze Locations
                </a>
              </li>
              <li>
                <a href="/blog/bookmarks-shortcuts-to-interesting-places/">
                  Bookmarks - Shortcuts to Interesting Places
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class={styles.bottomFooter}>
        <div className={styles.bottomFooterContent}>
          <img
            src="/images/logo-light.svg"
            style={{ width: '120px', height: '60px' }}
            alt="Atlas Logo"
          />
          <p>
            © Atlas {new Date().getFullYear()} / Your all-in-one map builder
          </p>
          <div className={styles.socialLinks}>
            <Link to="https://www.linkedin.com/company/atlasmapshq/">
              <Tooltip label="LinkedIn" withArrow>
                <div className={styles.socialLink}>
                  <LinkedInIcon size={20} />
                </div>
              </Tooltip>
            </Link>
            <Link to="https://x.com/AtlasMapsHQ">
              <Tooltip label="X/Twitter" withArrow>
                <div className={styles.socialLink}>
                  <XIcon size={20} />
                </div>
              </Tooltip>
            </Link>
            <Link to="https://www.instagram.com/life.at.atlas/">
              <Tooltip label="Instagram" withArrow>
                <div className={styles.socialLink}>
                  <InstagramIcon size={20} />
                </div>
              </Tooltip>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
)
