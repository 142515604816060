import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import { Menu, X } from 'react-feather'
import Logo from './Logo'
import styles from './Nav.module.scss'
import Button from './Button'
import { signUpClick } from '../../utils/tracker'
import {
  AcademicCapIcon,
  BarChartIcon,
  BookIcon,
  BookOpenIcon,
  BuildingIcon,
  ChevronDownSolidIcon,
  CityIcon,
  EditIcon,
  EditorModeIcon,
  GridIcon,
  LightningIcon,
  QuestionMarkCircleIcon,
  ShoppingCartIcon,
  TreeIcon,
  UserGroupIcon,
  VectorOperationsIcon
} from '@atlas-co/icons'

const Navigation = () => {
  const location = useLocation()
  const [currentPath, setCurrentPath] = useState(null)
  const [active, setActive] = useState(false)
  const [flyout, setFlyout] = useState(null)
  const [navHover, setNavHover] = useState(false)
  const ref = useRef(null)
  const [mobileActive, setMobileActive] = useState(false)
  const [scrolled, setScrolled] = useState(true)
  const lastScrollTop = useRef(0)
  const [onTop, setOnTop] = useState(true)

  useEffect(() => {
    setCurrentPath(location?.pathname)
  }, [])

  // const handleMenuToggle = () => setActive(!active)
  const handleMenuToggle = () => setActive(!active)

  const handleMobileToggle = () => setMobileActive(!mobileActive)

  const handleLinkClick = () => [active && handleMenuToggle()]

  const handleFlyout = value => {
    let flyout = null
    if (value === 'resources') {
      flyout = {
        description: 'resources',
        position: '500px',
        links: [
          {
            title: 'Guides',
            icon: <AcademicCapIcon size={24} />,
            description: 'Learn the basics',
            link: '/help/guides/'
          },
          {
            title: 'Documentation',
            icon: <BookIcon size={24} />,
            description: 'Atlas Docs',
            link: '/help/docs/'
          },
          {
            title: 'Blog',
            icon: <EditIcon size={24} />,
            description: 'Latest updates and news',
            link: '/resources/'
          },
          {
            title: 'Help Center',
            icon: <QuestionMarkCircleIcon size={24} />,
            description: 'Curated guides and FAQs',
            link: '/help/'
          },
          {
            title: 'Careers',
            icon: <UserGroupIcon size={24} />,
            description: 'Join the team',
            link: '/careers/'
          }
        ]
      }
    } else if (value === 'product') {
      flyout = {
        description: 'product',
        position: '0',
        links: [
          {
            title: 'Product Overview',
            icon: <GridIcon size={24} />,
            description: 'GIS in the browser',
            link: '/product/'
          },
          {
            title: 'Visualization',
            icon: <EditorModeIcon size={24} />,
            description: 'Upload and style data',
            link: '/product/visualization/'
          },
          {
            title: 'Spatial Analysis',
            icon: <VectorOperationsIcon size={24} />,
            description: 'Advanced GIS analysis',
            link: '/product/spatial-analysis/'
          },
          {
            title: 'Widgets',
            icon: <BarChartIcon size={24} />,
            description: 'Charts, tables, and more',
            link: '/product/widgets/'
          }
        ],
        mainLink: {
          title: 'Atlas',
          image: '/images/Product-overview-100.jpg',
          description: 'Available directly in your browser',
          link: '/product/'
        }
      }
    } else if (value === 'solutions') {
      flyout = {
        description: 'solutions',
        position: '150px',
        links: [
          {
            title: 'Buildings & Real Estate',
            icon: <BuildingIcon size={24} />,
            description: 'Investment and development',
            link: '/solutions/buildings-real-estate/'
          },
          {
            title: 'Energy & Infrastructure',
            icon: <LightningIcon size={24} />,
            description: 'Site selection, impact and output',
            link: '/solutions/energy-infrastructure/'
          },
          {
            title: 'Climate Risk & Sustainability',
            icon: <TreeIcon size={24} />,
            description: 'Upload and style data',
            link: '/solutions/climate-risk-sustainability/'
          },
          {
            title: 'Public Service & Administration',
            icon: <CityIcon size={24} />,
            description: 'Community engagement and planning',
            link: '/solutions/public-service-administration/'
          },
          {
            title: 'Retail & Marketing',
            icon: <ShoppingCartIcon size={24} />,
            description: 'Site selection and market analysis',
            link: '/solutions/retail-marketing/'
          }
        ]
      }
    }
    setFlyout(flyout)
  }

  const NavLink = ({ to, className, children, ...props }) => (
    <Link
      to={to}
      className={className}
      onClick={active && handleLinkClick}
      {...props}
    >
      {children}
    </Link>
  )

  useEffect(() => {
    const handleScroll = () => {
      const scrollY =
        document.body.scrollTop || document.documentElement.scrollTop
      if (scrollY <= lastScrollTop.current) {
        setScrolled(true) // visible
      } else {
        setScrolled(false) // not visible
      }
      lastScrollTop.current = scrollY <= 0 ? 0 : scrollY
      scrollY === 0 ? setOnTop(true) : setOnTop(false)
    }

    document.body.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return !mobileActive ? (
    <div ref={ref}>
      <nav
        className={`${styles.nav} ${active && styles.active} ${mobileActive &&
          styles.active} ${scrolled && styles.scrolled} ${onTop &&
          styles.onTop}`}
        onMouseLeave={() => setFlyout(null)}
      >
        <div className={styles.navContainer}>
          <Link to="/" onClick={handleLinkClick}>
            <Logo />
          </Link>
          <div className={styles.navLinks}>
            <NavLink
              to="/product/"
              className={`${styles.navLink} ${'/product/' === currentPath &&
                styles.active} ${flyout?.description === 'product' &&
                styles.hover}`}
              onMouseOver={() => handleFlyout('product')}
            >
              Product <ChevronDownSolidIcon />
            </NavLink>
            <span
              className={`${styles.navLink} ${'/solutions/' === currentPath &&
                styles.active} ${flyout?.description === 'solutions' &&
                styles.hover}`}
              onMouseOver={() => handleFlyout('solutions')}
            >
              Solutions <ChevronDownSolidIcon />
            </span>
            <NavLink
              to="/pricing/"
              className={`${styles.navLink} ${'/pricing/' === currentPath &&
                styles.active}`}
              onMouseOver={() => handleFlyout(null)}
            >
              Pricing
            </NavLink>
            <NavLink
              to="/community/maps/"
              className={`${styles.navLink} ${'/community/maps/' ===
                currentPath && styles.active}`}
              onMouseOver={() => handleFlyout(null)}
            >
              Examples
            </NavLink>
            {/* <NavLink
              to="/about"
              className={`${styles.navLink} ${'/about' === currentPath &&
                styles.active}`}
            >
              Company
            </NavLink> */}
            <NavLink
              to="/resources/"
              className={`${styles.navLink} ${'/resources/' === currentPath &&
                styles.active} ${flyout?.description === 'resources' &&
                styles.hover}`}
              onMouseOver={() => handleFlyout('resources')}
            >
              Resources <ChevronDownSolidIcon />
            </NavLink>
          </div>
          <div className={`${styles.rightContainer} ${styles.hideMobile}`}>
            <NavLink
              to="/contact/"
              className={`${styles.navLink} ${'/contact/' === currentPath &&
                styles.active}`}
            >
              Book demo
            </NavLink>
            <Button
              href="https://app.atlas.co/login?sign-in=true"
              variant="outline"
            >
              Login
            </Button>
            <Button
              href="https://app.atlas.co/login"
              onClick={signUpClick}
              className={'plausible-event-name=Signup'}
            >
              Sign up
            </Button>
          </div>
          <button
            className={styles.menuButton}
            onClick={() => handleMobileToggle()}
            aria-label="Toggle menu"
          >
            {active ? (
              mobileActive ? (
                <X />
              ) : (
                <Menu />
              )
            ) : mobileActive ? (
              <X />
            ) : (
              <Menu />
            )}
          </button>
          {flyout !== null && (
            <div
              className={styles.flyoutMenu}
              style={{ left: flyout.position }}
            >
              <div
                className={`${styles.flyoutColumns} ${!flyout.mainLink &&
                  styles.oneColumn}`}
              >
                {/* <p className={styles.flyoutDescription}>{flyout.description}</p> */}
                <div className={styles.flyoutGrid}>
                  {flyout.links.map(link => (
                    <NavLink to={link.link}>
                      <div className={styles.flyoutLink}>
                        <div className={styles.flyoutLinkSymbol}>
                          {link.icon}
                        </div>
                        <div className={styles.flyoutLinkContent}>
                          <h5>{link.title}</h5>
                          <p>{link.description}</p>
                        </div>
                      </div>
                    </NavLink>
                  ))}
                </div>
                {flyout.mainLink && (
                  <NavLink to={flyout.mainLink.link}>
                    <div className={styles.flyoutMainLink}>
                      {/* <div className={styles.flyoutMainLinkImage}>
                        <img src={flyout.mainLink.image} alt="" />
                      </div> */}
                      <div className={styles.flyoutMainLinkContent}>
                        <h5>{flyout.mainLink.title}</h5>
                        <p>{flyout.mainLink.description}</p>
                      </div>
                    </div>
                  </NavLink>
                )}
              </div>
            </div>
          )}
        </div>
      </nav>
    </div>
  ) : (
    <div>
      <nav className={`${styles.nav} ${mobileActive && styles.active}`}>
        <div className={styles.navContainer}>
          <Link to="/" onClick={handleLinkClick}>
            {flyout != 'none' || active ? (
              mobileActive ? (
                <Logo />
              ) : (
                <Logo />
              )
            ) : mobileActive ? (
              <Logo />
            ) : (
              <Logo />
            )}
          </Link>
          <div className={styles.navLinks}>
            <NavLink
              to="/"
              className={`${styles.navLink} ${'/' === currentPath &&
                styles.active}`}
            >
              Home
            </NavLink>
            <NavLink
              to="/product/"
              className={`${styles.navLink} ${'/product/' === currentPath &&
                styles.active}`}
            >
              Product
            </NavLink>
            <NavLink
              to="/pricing/"
              className={`${styles.navLink} ${'/pricing/' === currentPath &&
                styles.active}`}
            >
              Pricing
            </NavLink>
            <NavLink
              to="/community/maps/"
              className={`${styles.navLink} ${'/community/maps/' ===
                currentPath && styles.active}`}
            >
              Examples
            </NavLink>
            <NavLink
              to="/resources/"
              className={`${styles.navLink} ${'/resources/' === currentPath &&
                styles.active}`}
            >
              Resources
            </NavLink>
            <Button
              href="https://app.atlas.co/login"
              onClick={signUpClick}
              className={'plausible-event-name=Signup'}
            >
              Sign up
            </Button>
          </div>
          <button
            className={styles.menuButton}
            onClick={() => handleMobileToggle()}
            aria-label="Toggle menu"
          >
            {active ? (
              mobileActive ? (
                <X />
              ) : (
                <Menu />
              )
            ) : mobileActive ? (
              <X />
            ) : (
              <Menu />
            )}
          </button>
        </div>
      </nav>
    </div>
  )
}

export default Navigation
